<template>
  <SideBar
    class="SideForm__bar"
    :id="id"
    @onHiddenSidebar="handleCancel"
  >
    <SurveyDetailForm
      :survey="survey"
    />
  </SideBar>
</template>

<script>
import SideBar from '@/components/atoms/SideBar/SideBar.vue';
import SurveyDetailForm from './SurveyDetailForm.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    survey: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleCancel() {
      this.$emit('handleCancel');
    },
  },
  components: {
    SideBar,
    SurveyDetailForm,
  },
};
</script>

<style lang="sass">
  @import './SideForm.sass'
</style>
