<template>
  <div class="SideForm" v-if="hasSurvey">
    <SurveyHeader
      :survey="survey"
      :showClose="showClose"
      @handleCloseNotification="handleCloseNotification"
    />
    <div class="sp__v__1d5x" />
    <div class="SideForm__content">
      <div class="sp__v__1s5x" />
      <ContactBlock
        :user="survey.patient"
        :isAccepted="isRequestAccepted"
      />
      <hr />
      <div v-if="survey.answeredAt">
        <h2>{{this.$i18n.t('lb_result_survey')}}</h2>
        <hr>
        <div v-for="(item, index) of survey.surveyType.surveyTypesPartialsTypes"
          :key="index">
          <div v-if="showAnswer(standardizeName(item.name))">
            <p class="text__psbb">{{ item.title }}</p>
            <div v-if="item.partialsType.name === 'radio'">
              <ul>
                <li class="text-secondary">
                  {{ getJson(item.data)[getJson(survey.answers)[
                  `${standardizeName(item.name)}-radio`]] }}
                </li>
              </ul>
            </div>
            <div v-else-if="item.partialsType.name === 'checkbox'">
              <div v-for="(opt, index) of getOptions(standardizeName(item.name),
              item.partialsType.name)"
                   :key="index">
                <ul>
                  <li class="text-secondary">
                    {{ getJson(item.data)[opt] }}
                  </li>
                </ul>
              </div>

            </div>
            <div v-else>
              <ul>
                <li class="text-secondary">{{ getJson(survey.answers)[
                  standardizeName(item.name)
                  ] }}</li>
              </ul>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SurveyHeader from '@/components/molecules/Survey/SurveyHeader/SurveyHeader.vue';
import ContactBlock from '@/components/molecules/ContactBlock/ContactBlock.vue';

export default {
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    survey: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      surveyData: {
        id: this.survey?.id,
        createdAt: this.survey?.createdAt,
        answeredAt: this.survey?.answeredAt,
        deletedAt: this.survey?.deletedAt,
        patient: {
          id: this.survey?.patient?.id,
          createdAt: this.survey?.patient?.createdAt,
          name: this.survey?.patient?.name,
          lastname: this.survey?.patient?.lastname,
          gender: this.survey?.patient?.gender,
          origin: this.survey?.patient?.origin,
          dni: this.survey?.patient?.dni,
          dob: this.survey?.patient?.dob,
          tutor: this.survey?.patient?.tutor,
          insurance: this.survey?.patient?.insurance,
          hospital: this.survey?.patient?.hospital,
          email: this.survey?.patient?.email,
          phone: this.survey?.patient?.phone,
          requests: this.survey?.patient?.requests,
          contactWhatsApp: this.survey?.patient?.contactWhatsApp,
        },
        sendAt: this.survey?.sendAt,
        sendBy: this.survey?.sendBy,
        answers: this.survey?.answers,
        surveyType: {
          createdAt: this.survey?.surveyType?.createdAt,
          createdByData: this.survey?.surveyType?.createdByData,
          deletedAt: this.survey?.surveyType?.deletedAt,
          id: this.survey?.surveyType?.id,
          name: this.survey?.surveyType?.name,
          templatePath: this.survey?.surveyType?.templatePath,
          updatedAt: this.survey?.surveyType?.updatedAt,
        },
      },
      isRequestAccepted: true,
      responsetitles: [],
      responseAnswers: [],
    };
  },
  methods: {
    standardizeName(name) {
      const name2 = name.toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll('.', '_')
        .replaceAll(',', '_')
        .replaceAll(/[^\w\s]/g, '')
        .trim();
      const regex = /[^a-zA-Z _ 0-9.]+/g;
      return name2.replaceAll(regex, '');
    },
    showAnswer(itemName) {
      const answers = this.getJson(this.survey.answers);
      const hasCheckbox = this.survey.answers.search(`${itemName}-checkbox-`) !== -1;
      const hasRadio = this.survey.answers.search(`${itemName}-radio`) !== -1;
      return !!(answers[itemName] || hasCheckbox || hasRadio);
    },
    getJson(data) {
      return JSON.parse(data);
    },
    handleCloseNotification() {
      this.$emit('handleCloseNotification');
    },
    getOptions(name, type) {
      const obj = JSON.parse(this.survey.answers);
      const arr = [];

      Object.keys(obj).forEach((key) => {
        if (key.includes(`${name}-${type}`)) {
          arr.push(
            parseInt(key.substring(key.length - 1), 10),
          );
        }
      });

      return arr;
    },
  },
  computed: {
    hasSurvey() {
      return this.survey?.id !== undefined;
    },
  },
  watch: {
    request(newValue) {
      this.surveyData.id = newValue.id;
      this.surveyData.createdAt = newValue.createdAt;
      this.surveyData.deletedAt = newValue.deletedAt;
      this.surveyData.answeredAt = newValue.answeredAt;
      this.surveyData.patient = newValue.patient;
      this.surveyData.sendAt = newValue.sendAt;
      this.surveyData.sendBy = newValue.sendBy;
      this.surveyData.surveyType = newValue.surveyType;
    },
  },
  components: {
    SurveyHeader,
    ContactBlock,
  },
};
</script>

<style lang="sass" scoped>
@import './SideForm.sass'
</style>
