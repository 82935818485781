<template>
  <div class="SurveyHeader" v-if="getSurvey">
    <div class="SurveyHeader__userBlock">
      <p class="SurveyHeader__title text-primary"
        >
        {{ `${survey.patient.name} ${survey.patient.lastname}` }}
      </p>
      <div class="sp__2x" />
    </div>
    <p class="SurveyHeader__subtitle text__sr">
      {{ `${i18n.t('lb_survey_type')}: ${survey.surveyType.name}` }}
    </p>
    <button
      v-if="showClose"
      @click.prevent="handleCloseNotification"
      class="SurveyHeader__closeButton">
      <img src="@/assets/actions/close.svg" />
    </button>
  </div>
</template>

<script>

export default {
  props: {
    showClose: {
      type: Boolean,
      default: false,
    },
    survey: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleCloseNotification() {
      this.$emit('handleCloseNotification');
    },
  },
  computed: {
    getSurvey() {
      return this.survey;
    },
  },
  components: {
  },
};
</script>

<style lang="sass" scoped>
  @import './SurveyHeader.sass'
</style>
