<template>
      <div class="position-relative">
        <div class="buttonAbsolute">
          <b-button
            @click.prevent="showCreateModal"
            variant="primary"
            size="sm"
          >
            {{ `+ ${$i18n.t('btn_survey_new')}` }}
          </b-button>
        </div>
        <div class="sp__v__2x"/>
        <TableGroup
          id="survey"
          :items="surveyList"
          :pagination="pagination"
          :isLoading="isTableLoading"
          :showFilterButton="false"
          :searchText="searchText"
          @handleSearch="handleSearchByInput"
          @handleSearchByFilter="handleSearchByFilter"
          @handlePaginate="handleFilterSearch"
          @handleCleanSearch="handleCleanSearch"
        >
          <SurveyTable
            ref="surveyTable"
            :surveyItems="surveyList"
            :showActions="false"
            :isFormShowed="showDetail"
            @handleSelectRow="handleSelectRow"
            :canDelete="false"
          />
        </TableGroup>

      <SurveyDetail
        :show="showDetail"
        id="survey-detail"
        :survey="survey"
        @handleCancel="closeDetail"
      />
      <CreateSurvey
        v-if="showCreateSurvey"
        :patientsList="patientsList"
        @addSurvey="addSurvey"
        @handleCancel="closeCreateModal"
      />
      <ImportPatientModal
        id="requestFormImportPatientModal"
        :isCsvLoading="isCsvLoading"
        @handleUploadFile="handleUploadImportPatient"
      />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TableGroup from '@/components/molecules/Tables/TableGroup/TableGroup.vue';
import SurveyTable from '@/components/molecules/Tables/SurveyTable/SurveyTable.vue';
import SurveyDetail from '@/components/organisms/Survey/SurveyDetail.vue';
import SurveyModel from '@/domain/survey/SurveyModel';
import CreateSurvey from '@/components/organisms/Survey/CreateSurvey.vue';
import ImportPatientModal from '@/components/molecules/Modal/ImportPatientModal.vue';
import {
  uploadCsv,
} from '@/helpers/ComponentHelper';
import { getFiltersGroup, getOrdenation } from '@/helpers/ApiHelper';

export default {
  data() {
    return {
      surveyList: [],
      patientsList: [],
      showCreateSurvey: false,
      pagination: {},
      survey: {},
      showDetail: false,
      searchText: null,
      sort: {
        name: 'createdAt',
        type: 'desc',
      },
      filters: {
        name: null,
        surveyType: null,
      },
      isTableLoading: false,
      isCsvLoading: false,
    };
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleUploadImportPatient(document) {
      let requestData = uploadCsv(document);
      requestData = {
        ...requestData,
        isExternalPatient: true,
      };

      this.isCsvLoading = true;

      this.$store.dispatch('patient/importPatients', requestData)
        .then((res) => {
          this.patientsList = res.data.collection;
          this.closeImportModal();
          this.isCsvLoading = false;
        })
        .catch(() => {
          this.isCsvLoading = false;
        });
    },
    closeImportModal() {
      this.$bvModal.hide('requestFormImportPatientModal');
    },
    handleChangeSurveyType(value) {
      this.filters.surveyType = value;
    },
    handleCleanSearch() {
      this.filters.name = null;
      this.searchText = null;
      this.sort.name = null;
      this.sort.type = null;

      this.handleSearch();
    },
    handleSearchByFilter() {
      this.handleFilterSearch();
      this.$root.$emit('bv::toggle::collapse', 'survey-sidebar');
    },
    getFilters() {
      const filtersGroup = [];

      if (this.searchText !== null) {
        filtersGroup.push(
          `name:${this.searchText}`,
        );
      }

      return getFiltersGroup(filtersGroup, this.filters);
    },
    getRequestData(page = 1) {
      return {
        page,
        filters: this.getFilters(),
        ordenation: getOrdenation(this.sort),
      };
    },
    handleSearchByInput(text) {
      if (text !== null) {
        this.searchText = text;
        this.handleFilterSearch();
      } else {
        this.searchText = null;
      }
    },
    handleFilterSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('survey/filterSurvey', this.getRequestData(page))
        .then((res) => {
          this.surveyList = res.surveyList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    handleSearch(page = 1) {
      this.isTableLoading = true;

      this.$store.dispatch('survey/getAllSurvey', {
        page,
        itemsPerPage: 10,
        uuid: this.user.uuid,
      })
        .then((res) => {
          this.surveyList = res.surveyList;
          this.pagination = res.pagination;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    showCreateModal() {
      this.showCreateSurvey = true;
    },
    closeCreateModal() {
      this.showCreateSurvey = false;
    },
    closeDetail() {
      this.hideDetailView();
      this.$refs.requestTable.clearSelected();
    },
    showDetailView() {
      this.showDetail = true;
    },
    hideDetailView() {
      this.showDetail = false;
    },
    handleSelectRow(item) {
      if (item?.answers) {
        this.setSurvey(item);

        if (!this.showDetail) {
          this.$root.$emit('bv::toggle::collapse', 'survey-detail-sidebar');
        }

        this.showDetailView();
      }
    },
    setSurvey(data) {
      this.survey = data;
    },
    addSurvey(data) {
      data.patientsList.forEach((patient) => {
        const newSurvey = new SurveyModel({
          id: data.id,
          sendBy: this.user.uuid,
          patient,
          surveyType: data.surveyType,
          request: data.request,
          sendAt: data.sendAt,
          answeredAt: data.answeredAt,
          createdAt: data.createdAt,
          deletedAt: data.deletedAt,
        });

        this.surveyList.unshift(newSurvey);
        this.incrementPagination();
      });

      this.closeCreateModal();
    },
    incrementPagination() {
      this.pagination.totalItems += 1;
      this.pagination.itemsPerPage += 1;
    },
  },
  computed: {
    ...mapGetters('user', {
      user: 'getCurrentUser',
      isVidasprime: 'isVidasprime',
    }),
  },
  components: {
    TableGroup,
    SurveyTable,
    CreateSurvey,
    SurveyDetail,
    ImportPatientModal,
  },
};
</script>

<style lang="sass" scoped>
  @import "../Survey.sass"
</style>
