<template>
  <div class="Table">
    <b-table
      :items="items"
      :fields="colums"
      hover
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #cell(patient)="data">
        <UserSmallInfo
          :icon="getIcon(data.item.avatar)"
          :label="`${data.item.patient.name} ${data.item.patient.lastname}`"
          :text="data.item.patient.origin.name"
        />
      </template>
      <template #cell(email)="data">
          {{ data.item.patient.email }}
      </template>
      <template #cell(surveyType)="data">
        <Badge variant="neutral">
          {{ data.item.surveyType.name }}
        </Badge>
      </template>
      <template #cell(sendAt)="data">
        <span class="text__sr text-secondary">{{ data.item.sendAt | formatDate }}</span>
      </template>
      <template #cell(answeredAt)="data">
        <span class="text__sr text-secondary">{{ data.item.answeredAt | formatDate }}</span>
      </template>
      <template #cell(actions)>

      </template>
    </b-table>
  </div>
</template>

<script>
import UserSmallInfo from '@/components/atoms/User/UserSmallInfo/UserSmallInfo.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import { getAvatarIcon } from '@/helpers/UserHelper';
import i18n from '@/plugins/vue-i18n';

export default {
  props: {
    surveyItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      items: this.surveyItems,
    };
  },
  computed: {
    colums() {
      return [
        {
          key: 'patient',
          label: i18n.t('lb_patient'),
          sortable: true,
        },
        {
          key: 'email',
          label: i18n.t('lb_email'),
          sortable: true,
        },
        {
          key: 'surveyType',
          label: i18n.t('lb_survey_type'),
          sortable: true,
        },
        {
          key: 'sendAt',
          label: i18n.t('lb_survey_send_at'),
          sortable: true,
        },
        {
          key: 'answeredAt',
          label: i18n.t('lb_survey_answered_at'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
  },
  methods: {
    onRowSelected(items) {
      this.$emit('handleSelectRow', items[0]);
    },

    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },

    getIcon(avatar) {
      return getAvatarIcon(avatar);
    },
  },
  watch: {
    sortBy(newValue) {
      this.$emit('handleSortAsc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    UserSmallInfo,
    Badge,
  },
};
</script>

<style lang="sass" scoped>
@import '../Table.sass'
</style>
